var draggable_order;

$(document).ready( function(){
    // initialise the snow efect
    $.fn.snow({ minSize: 5, maxSize: 100, newOn: 500, flakeColor: '#0099FF' });
    // check document length and make it full screen if it is not
    setPanelSize();
    // draggable objects
    $(".draggable").dragsort({dragEnd: saveOrder});
    // checkboxes to look like switches
    $(".cb-switch").bootstrapSwitch();
    // $(".cb-switch").bootstrapSwitch({ size: 'small', onText: 'Yes', offText: 'No'});
});

//-- navbar active switching --//
$(".nav a").on('click', function(){
    $(".nav").find(".active").removeClass('active');
    $(this).parent().addClass('active');
});

$("body")
    .on('click', "#admin_save_changes", function() {
        // ensure we have the reading order set
        if (typeof draggable_order == 'undefined') {
            saveOrder();
        }
        // append the required data to the form so that the controller can do its job
        $("#admin-form")
            .append('<input type="hidden" value="' + draggable_order + '" name="reading_order">')
            .submit();
    })
    .on("click", ".reading-admin", function() {
        return readingAdminModal($(this));
    });

var setPanelSize = function() {
    var sfmsPanel = $(".sfms-panel"),
        sfmsPanelPosition = sfmsPanel.position(),
        sfmsPanelHeight = sfmsPanel.height(),
        windowHeight = $(window).height();
    if ((sfmsPanelHeight + sfmsPanelPosition.top) < windowHeight) {
        sfmsPanel.height(windowHeight - sfmsPanelPosition.top);
    }
};

function saveOrder() {
    draggable_order = $(".draggable div").map(function() { return $(this).attr('data-itemid'); }).get();
    //alert('order : ' + draggable_order);
};

function readingAdminModal(clicked) {
    //=========================//
    // Set up the modal window //
    //=========================//
    var clicked_element;
    var mode;
    var footer_message = '';
    var send_data;
    if (typeof(clicked.attr('data-mode')) == 'undefined') {
        clicked_element = clicked.parent();
        mode = clicked_element.attr('data-mode');
    } else {
        clicked_element = clicked;
        mode = clicked_element.attr('data-mode');
    }
    switch(mode) {
        case 'add':
            $("#reading-admin-name-edit").val('');
            $("#reading-admin-description-edit").val('');
            send_data = true;
            showHideReadingAdminModalElements('edit');
            break;
        case 'update':
            $("#reading-admin-name-edit").val($("#reading-type-name-" + clicked_element.attr('data-id')).html());
            $("#reading-admin-description-edit").val($("#reading-type-description-" + clicked_element.attr('data-id')).html());
            send_data = true;
            showHideReadingAdminModalElements('edit');
            break;
        case 'delete':
            $("#reading-admin-name-view").html($("#reading-type-name-" + clicked_element.attr('data-id')).html());
            $("#reading-admin-description-view").html($("#reading-type-description-" + clicked_element.attr('data-id')).html());
            send_data = false;
            showHideReadingAdminModalElements('view');
            footer_message = framework.translations['are_you_sure'];
            break;
        default:
            alert("ERROR - incorrect mode, or mode not set, for reading admin. Contact support");
    }
    $("#reading-admin-footer-message").html(footer_message);
    $("#reading-admin-title").html(framework.translations[mode] + ' ' + framework.translations['reading']);
    // $(".modal-body").html('<p>This was openned in ' + majc_text + ' mode</p>');
    // $("#reading-admin-submit-button").val(framework.translations[mode]);
    $("#reading-admin-submit-button")
        .val(framework.translations[mode])
        .on('click', function() {
            $("#reading-types-admin-form")
                .attr('action', clicked_element.attr('href'))
                .submit();
        });
    // $("#reading-admin-submit-button")
    //     .html(framework.translations[mode])
    //     .on('click', function() {
    //         if (send_data) {
    //             $("#admin-form")
    //                 .append('<input type="hidden" value="' + $("#reading-admin-name-edit").val() + '" name="reading_name">')
    //                 .append('<input type="hidden" value="' + $("#reading-admin-description-edit").val() + '" name="reading_description">')
    //         }
    //         window.location.assign(clicked_element.attr('href'));
    //     });
    $("#reading-admin-modal").modal();
    return false;

}

function showHideReadingAdminModalElements(mode) {
    if (mode == 'edit') {
        $("#reading-admin-modal .reading-admin-edit").show();
        $("#reading-admin-modal .reading-admin-view").hide();
    } else {
        $("#reading-admin-modal .reading-admin-edit").hide();
        $("#reading-admin-modal .reading-admin-view").show();
    }
}